//=== Slick
$slick-font-path: "~slick-carousel/slick/fonts/" !default;
$slick-loader-path: "~slick-carousel/slick/" !default;

$color-white: #fff;
$color-black: #000;
$color-pink: #b20057;
$color-text: #232323;
$color-light-gray: #ecf1f7;

$color-pink-25: rgba(178, 0, 87, 0.25);

// === Review
$color-star-off: #adb5bd;
$color-star-on: #eeb826;

// === CF7 EFFECT
$color-form-text: $color-text;
$color-form-filled: $color-pink;

//=== Slick
$slick-font-path: "~slick-carousel/slick/fonts/" !default;
$slick-loader-path: "~slick-carousel/slick/" !default;
$slick-dot-size: 10px;
$slick-opacity-default: 1;
$slick-dot-color: $color-pink;
