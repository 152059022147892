// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

// Boostrap option
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;

// Border
$border-width: 1px !default;
$border-color: $color-text !default;

$border-radius: .25rem !default;
$border-radius-lg: .3rem !default;
$border-radius-sm: .2rem !default;

// Transitions
$transition-base: all .2s ease-in-out !default;
$transition-fade: opacity .15s linear !default;
$transition-collapse: height .35s ease !default;

// Spacers
$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * .25),
                        2: ($spacer * .5),
                        3: $spacer,
                        4: ($spacer * 1.5),
                        5: ($spacer * 3)
                ),
                $spacers
);

// Modals
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;

// Padding applied to the modal body
$modal-inner-padding: 1rem !default;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: .5rem !default;

$modal-dialog-margin: .5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: 1.5 !default;

$modal-content-color: null !default;
$modal-content-bg: $color-white !default;
$modal-content-border-color: rgba($color-black, .2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-border-radius: $border-radius-lg !default;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs: 0 .25rem .5rem rgba($color-black, .5) !default;
$modal-content-box-shadow-sm-up: 0 .5rem 1rem rgba($color-black, .5) !default;

$modal-backdrop-bg: $color-black !default;
$modal-backdrop-opacity: .5 !default;
$modal-header-border-color: $color-black !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding-y: 1rem !default;
$modal-header-padding-x: 1rem !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform .3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;

// === Boostrap Mixins
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/box-shadow";
@import "~bootstrap/scss/mixins/border-radius";
@import "~bootstrap/scss/mixins/transition";

// === Bootsrap components
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/utilities/spacing";
