.btn {
  font-size: 1rem;
  padding: .5rem 1rem;
  border-radius: .25rem !important;
}

.btn-primary {
  color: $color-white;
  background: $color-pink !important;
  border: 1px solid $color-pink !important;
  transition: background-color ease .3s, color ease .3s;

  &:hover {
    color: $color-pink;
    background: $color-white !important;
  }
}

.btn-secondary {
  color: $color-text;
  background: $color-white;
  border: 1px solid $color-white;
  transition: background-color ease .3s, color ease .3s;

  &:hover {
    color: $color-text;
    background: $color-white;
  }

  &.btn-outlined {
    background: transparent;

    &:hover {
      color: $color-text;
      background: $color-white;
    }
  }
}


.t-entry-readmore {
  .btn {
    &.btn-default {
      background: $color-pink !important;
      padding: 0.5rem 1rem;
      color: $color-white !important;
      border: 1px solid $color-pink !important;
      transition: color ease 0.3s, background-color ease 0.3s;

      &:focus:not(:hover) {
        color: $color-white !important;
      }

      & > i {
        margin-right: 0.5rem;
        font-size: 1rem;
        top: 0;
      }
      &:not(.btn-hover-nobg):not(.icon-animated):not(.btn-flat) {
        &:hover {
          background-color: transparent;
          color: $color-pink !important;
          border-color: $color-pink !important;
        }
      }

      &:hover {
        background-color: transparent;
        color: $color-pink !important;
      }

    }
  }
}
