.review_note {
  .wpcf7-list-item {
    input {
      display: none;
    }

    .wpcf7-list-item-label {
      font-size: 0;
      cursor: pointer;
      @include font-awesome-before($fa-var-star, 0);

      &:before {
        padding: .125rem;
        font-size: 1rem;
        color: $color-star-off;
        transition: color ease .3s;
      }
    }

    &.star-on {
      .wpcf7-list-item-label {
        &:before {
          color: $color-star-on;
        }
      }
    }
  }
}

.fuhrmann-review-item {
  .review-item-meta {
    display: flex;
    align-items: center;
    margin-top: .5rem;

    .review-item-date {
      order: 1;
      margin-left: .25rem;
      font-size: .9rem;
      color: $color-pink;

      &:before {
        margin: 0 .25rem;
        content: '-';
        color: $color-text;
      }
    }
  }

  .review-item-note {
    .star {
      @include font-awesome-before($fa-var-star, 0);

      &:before {
        font-size: .9rem;
        color: $color-star-off;
      }

      &.star-on {
        &:before {
          color: $color-pink;
        }
      }
    }

    .star-svg {
      svg {
        height: 1.5rem;

        .fuhrmann-gradient {
          fill: $color-star-off;
        }
      }

      &.star-on {
        svg {
          .fuhrmann-gradient {
            fill: $color-pink;
          }
        }
      }
    }
  }

  .review-item-content {
    p {
      margin-top: .5rem;
    }
  }
}
