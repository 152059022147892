// @see https://fontawesome.com/how-to-use/on-the-web/setup/using-package-managers

$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts/";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";

@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";

@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

$use-fa-pro: true !default;

@mixin font-awesome($code) {
  @extend %fa-icon;

  @if $use-fa-pro {
    @include font-awesome-pro($code);
  }
  @else {
    // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
    @extend .fa;

    // stylelint-disable-next-line declaration-no-important
    text-decoration: none !important;
    content: $code;
  }

  @content;
}

@mixin font-awesome-pro($code) {
  @include font-awesome-pro-solid($code);
}

@mixin font-awesome-pro-solid($code) {
  // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
  @extend .fas;
  content: fa-content($code);
}

@mixin font-awesome-pro-regular($code) {
  // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
  @extend .far;
  content: fa-content($code);
}

@mixin font-awesome-pro-light($code) {
  // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
  @extend .fal;
  content: fa-content($code);
}

@mixin font-awesome-pro-brand($code) {
  // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
  @extend .fab;
  content: fa-content($code);
}

@mixin font-awesome-before($code, $margin: .25em, $floating: false, $fa-style: "solid") {
  &::before {
    @if $fa-style == "regular" {
      @include font-awesome-pro-regular($code);
    }
    @else if $fa-style == "light" {
      @include font-awesome-pro-light($code);
    }
    @else if $fa-style == "brand" {
      @include font-awesome-pro-brand($code);
    }
    @else {
      @include font-awesome($code);
    }

    @if $floating {
      float: left;
      line-height: inherit;
    }

    margin-right: $margin;

    @content;
  }
}

@mixin font-awesome-after($code, $margin: .25em, $floating: false, $fa-style: "solid") {
  &::after {
    @if $fa-style == "regular" {
      @include font-awesome-pro-regular($code);
    }
    @else if $fa-style == "light" {
      @include font-awesome-pro-light($code);
    }
    @else if $fa-style == "brand" {
      @include font-awesome-pro-brand($code);
    }
    @else {
      @include font-awesome($code);
    }

    @if $floating {
      float: right;
      line-height: inherit;
    }

    margin-left: $margin;

    @content;
  }
}
