.sc-publication-list {
  &.slick-initialized {
    article {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.post-content {
  ul.slick-dots:not(.no-list) {
    position: static;
    bottom: 0;
    padding: 0 !important;
    margin-top: 1rem;
    margin-bottom: -1rem;
  }
}

// UNCODE CAROUSEL DOT COLOR
.style-light {
  .owl-dots-outside {
    .owl-dots {
      .owl-dot {
        span {
          background: $color-pink-25;
        }
        &.active {
          span {
            background : $color-pink;
          }
        }
      }
    }
  }
}
