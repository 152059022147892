
footer.site-footer {
  .style-dark {
    a {
      font-size: 0.75rem;
      color: $color-text;

      &:hover {
        color: $color-text;
        text-decoration: underline;
      }
    }

    .site-info {
      div {
        font-size: 1rem;
      }
    }
  }

  .footer-center {
    padding-top: 1rem;
    padding-bottom: 1rem;

    .site-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 1.5;
      color: $color-text;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }

      .separator {
        display: none;
        @media (min-width: 960px) {
          display: block;
          margin: 0 .25rem;
        }
      }

      a {
        color: $color-text;
        transition: color ease .4s;

        &:hover {
          text-decoration: underline;
        }
      }

      .logo {
        margin-top: .5rem;
      }
    }
  }

  .footer-block-logo {
    margin-bottom: 2rem;
    @media (min-width: 960px) {
      margin-bottom: 0;
    }

    .fluid-svg {
      .icon-media {
        max-width: 100%;

        svg {
          max-width: 100%;

          * {
            fill: $color-text;
          }
        }

      }
    }
  }
}
