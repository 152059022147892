.block-home-header {
  .heading-text {
    & > * {
      text-shadow: .125rem .125rem .125rem rgba(0, 0, 0, .4);
    }
    .h2 {
      line-height: 1.3;
    }
  }
  .uncode_text_column {
    p {
      text-shadow: .125rem .125rem .125rem rgba(0, 0, 0, .4);
    }
    &.text-lead {
      p{
        line-height: 1.3;
      }
    }
  }
}

