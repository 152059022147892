// CUSTIMIZATION FOR UNCODE GRID
.row-flex {
  .row-inner {
    display: flex;
    flex-wrap: wrap;

    .col-lg-1 {
      flex: 0 0 100%;
      max-width: 100%;
      height: auto;
      @include media-breakpoint-up(lg) {
        flex: 0 0 8.33%;
        max-width: 8.33%;
      }
    }

    .col-lg-2 {
      flex: 0 0 100%;
      max-width: 100%;
      height: auto;
      @include media-breakpoint-up(lg) {
        flex: 0 0 16.666%;
        max-width: 16.666%;
      }
    }

    .col-lg-3 {
      flex: 0 0 100%;
      max-width: 100%;
      height: auto;
      @include media-breakpoint-up(lg) {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }

    .col-lg-4 {
      flex: 0 0 100%;
      max-width: 100%;
      height: auto;
      @include media-breakpoint-up(lg) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
    }

    .col-lg-5 {
      flex: 0 0 100%;
      max-width: 100%;
      height: auto;
      @include media-breakpoint-up(lg) {
        flex: 0 0 41.66666%;
        max-width: 41.66666%;
      }
    }

    .col-lg-6 {
      flex: 0 0 100%;
      max-width: 100%;
      height: auto;
      @include media-breakpoint-up(lg) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    .col-lg-7 {
      flex: 0 0 100%;
      max-width: 100%;
      height: auto;
      @include media-breakpoint-up(lg) {
        flex: 0 0 58.33%;
        max-width: 58.33%;
      }
    }

    .col-lg-8 {
      flex: 0 0 100%;
      max-width: 100%;
      height: auto;
      @include media-breakpoint-up(lg) {
        flex: 0 0 66.66%;
        max-width: 66.66%;
      }
    }

    .col-lg-9 {
      flex: 0 0 100%;
      max-width: 100%;
      height: auto;
      @include media-breakpoint-up(lg) {
        flex: 0 0 75%;
        max-width: 75%;
      }
    }

    .col-lg-10 {
      flex: 0 0 100%;
      max-width: 100%;
      height: auto;
      @include media-breakpoint-up(lg) {
        flex: 0 0 83.33%;
        max-width: 83.33%;
      }
    }

    .col-lg-11 {
      flex: 0 0 100%;
      max-width: 100%;
      height: auto;
      @include media-breakpoint-up(lg) {
        flex: 0 0 91.66%;
        max-width: 91.66%;
      }
    }

    .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%;
      height: auto;
    }
  }
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-lg-0 {
  @include media-breakpoint-up(lg) {
    order: 0;
  }
}

.order-lg-1 {
  @include media-breakpoint-up(lg) {
    order: 1;
  }
}

.responsive-uncode-padding {
  .double-top-padding {
    padding-top: 0 !important;
    @include media-breakpoint-up(lg) {
      padding-top: 72px !important;
    }
  }
}
