.block-home-list {
  ul.icons {
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    li {
      display: flex;
      flex: 0 0 55%;
      margin-top: 0;
      margin-bottom: 1rem;
      font-weight: 700;
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        flex: 1;
      }

      .fa {
        line-height: 1.5;
      }
    }
  }
}

.block-home-icons {
  .h3 {
    font-weight: 700;
    text-transform: uppercase;
  }
}

.block-home-dossiers.row-container {
  .uncell {
    .uncont {
      .h3 {
        margin-top: 0;
        font-size: 1.5rem;
        font-weight: 600;
      }

      .icon-box {
        display: block;
        width: auto;
        padding: 1rem;
        margin: .5rem !important;
        border: 2px solid $color-white;
        transition: border-color ease .3s;

        &:hover {
          border-color: $color-pink;
        }

        &:first-child {
          padding: 1rem !important;
        }

        &:last-child {
          padding: 1rem !important;
        }
      }
    }
  }
}

.block-home-news-list {
  .tmb {
    .t-cat-over {
      top: 1rem;
      left: 1rem;
      padding: 0;
      background: $color-white;
      opacity: 1;
      transition: background-color .3s ease;
      transition-delay: 0s !important;

      .t-entry-category {
        a {
          display: block;
          padding: .5rem .75rem;
          font-size: .75rem;
          font-weight: 600;
          color: $color-text!important;
          text-transform: uppercase;
          transition: color .3s ease;
        }
      }

      &:hover {
        background: $color-pink;
        transition-delay: 0s !important;

        .t-entry-category {
          a {
            color: $color-white;
          }
        }
      }
    }

    .t-entry-meta {
      .t-entry-date {
        font-size: .875rem;
        font-weight: 700;
        color: $color-pink !important;
      }
    }
  }
}
