strong {
  color: $color-pink;
}
.style-light p strong{
  @extend strong;
}

// === UNCODE FIX
.disable-hover .page-wrapper * {
  pointer-events: initial !important;
}

.uncode-divider-wrap {
  overflow: hidden;
}

// === LOGO CUSTOMIZATION
.logo-black {
  svg {
    width: 250px;
    max-width: 100%;
    height: auto;

    * {
      fill: #000;
    }
  }
}

// === ICON BOX WIDGET
.flex-icon-box {
  .icon-box {
    display: flex;
    align-items: flex-start;

    .icon-box-icon {
      display: block;
      width: auto;
      margin-top: 0;

      .fa-1x {
        font-size: 1.1rem;
        line-height: 2;
      }
    }

    .icon-box-content {
      padding-left: 1rem;
    }
  }
}


// === SVG ICON COLOR
svg {
  .color-dark {
    fill: $color-text;
  }

  .color-pink {
    fill: $color-pink;
  }

  .no-color {
    fill: none;
  }
}
