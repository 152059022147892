.nl-form-title {
  font-size : 1.1rem;
  font-weight : bold;
  text-transform : uppercase;
}
.nl-form {
  .sib-form-title {
    margin-bottom: .5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  .nl-form-content {
    display: flex;
  }

  .sib-email-area {
    flex: 0 0 80%;
    max-width: 80%;

    .sib-email-input {
      width: 100%;
      height: 100%;
      margin: 0;
      color: $color-text;
      background: #fff;
      border-radius: 0;

      &::placeholder {
        color: $color-text;
      }
    }
  }

  .sib-submit-area {
    flex: 0 0 20%;
    max-width: 20%;

    & > button {
      display: block;
      width: 100%;
      height: 100%;
      padding: .25rem .5rem;
      color: $color-white;
      background: $color-pink !important;
      @include media-breakpoint-up(xl) {
        padding: .25rem 1rem;
      }
    }
  }
}
.wpcf7 {
  form.sent{
    .tips-form {
      & + .wpcf7-response-output{
        color: #fff;
        background: rgba(70,180,80, .25);
      }
    }
  }

}
