.top-menu {
  padding: 1rem 0;

  .menu-horizontal {
    .menu-smart {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .menu-item {
        a {
          font-size: 1rem;
          text-transform: uppercase;

          & > i {
            display: none;
          }
        }
      }

      .btn-menu-top {
        & > a {
          @extend .btn, .btn-primary;
        }

        &:last-child {
          & > a {
            @include media-breakpoint-up(lg) {
              padding: .5rem 1rem !important;
              margin-left: .5rem;
            }
          }
        }
      }
    }
  }
}

.menu-primary {
  .main-menu-container {
    .menu-primary-inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
      }

      & > li {
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: auto;
        }

        &.btn-menu {
          & > a {
            background: transparent;
            border: 1px solid $color-white;
            border-radius: .25rem;
            transition: background-color ease .3s, color ease .3s;

            &:hover {
              color: $color-text;
              background: $color-white;
            }

            @include media-breakpoint-up(lg) {
              padding: .5rem 1rem !important;
            }
          }

          &:last-child {
            & > a {
              @include media-breakpoint-up(lg) {
                padding: .5rem 1rem !important;
              }
            }
          }
        }
      }
    }
  }
}


// UNCODE MENU UNSTICKED STYLE
body:not(.menu-force-opacity) {
  .style-dark-override:not(.is_stuck):not(.is_mobile_open).menu-transparent {
    .menu-horizontal-inner {
      & > .nav {
        & > .menu-smart {
          & > li {
            &.menu-item {
              & > a:hover {
                color: $color-text !important;
              }
              &:not(.btn-menu){
                & > a:hover {
                  color: $color-white !important;
                  text-decoration: underline;
                }
              }
            }
            &.current-menu-item:not(.menu-item-type-custom) {
              & > a {
                color: $color-white !important;

                &:hover {
                  color: $color-text !important;
                }
              }

              &:not(.btn-menu) {
                & > a {
                  text-decoration: underline;
                  &:hover {
                    color: $color-white !important;
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// UNCODE MENU STICKED STYLE
.menu-wrapper.menu-sticky {
  .is_stuck {
    .menu-container {
      background: $color-light-gray;
      @include media-breakpoint-up(lg) {
        position: static !important;
      }
      .mobile-menu-button-dark {
        .lines{
          background: $color-text;
          &:after,&:before {
            background: $color-text;
          }
        }
      }

      .menu-smart {
        a {
          color: $color-text;

          &:hover {
            color: $color-pink;
          }
        }

        li.current-menu-item:not(.btn-menu) {
          a {
            color: $color-pink;
          }
        }

        li.btn-menu {
          & > a {
            border: 1px solid $color-text;

            &:hover {
              color: $color-text;
            }
          }
        }
      }
    }

    .main-logo {
      svg {
        .style-light {
          fill: $color-text;
        }
      }
    }
  }
}


@include media-breakpoint-up(lg) {
  body[class*=hmenu] {
    .menu-wrapper.with-header {
      display: block;
      position: fixed;
      z-index: 100;
    }
  }
}

